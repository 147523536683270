/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.header-padding').css('height', $('header').innerHeight() + 'px');

        $(window).resize(function() {
          $('.preserve-height').responsiveEqualHeightGrid();
          $('.header-padding').css('height', $('header').innerHeight() + 'px');
        });

        $(window).scroll(function() {
          if ($(window).scrollTop() > 0) {
            $('header').addClass('fixed');
          } else {
            $('header').removeClass('fixed');
          }
        });

        $('.mobile-menu-trigger .search-icon-open').on('click', function() {
            var nav = $('.mobile-menu');
            var search = $('.search-header');
            var close_icon = $('.search-icon-close');
            var open_icon = $('.search-icon-open');
            var search_icon = $('.search-icon');
            var search_close_icon = $('.close-icon');
            nav.css('display', 'flex');
            search.hide();
            search_close_icon.hide();
            search_icon.show();
            open_icon.hide();
            close_icon.show();
            $('.header-right').removeClass('active');
        });

        $('.mobile-menu-trigger .search-icon-close').on('click', function() {
            var nav = $('.mobile-menu');
            var search = $('.search-header');
            var close_icon = $('.search-icon-close');
            var open_icon = $('.search-icon-open');
            var search_icon = $('.search-icon');
            var search_close_icon = $('.close-icon');
            nav.hide();
            search_close_icon.hide();
            search_icon.show();
            search.hide();
            open_icon.show();
            close_icon.hide();
            $('.header-right').removeClass('active');
        });

        $('.search-trigger .search-icon').on('click', function() {
          var nav = $('.nav-primary');
          var search = $('.search-header');
          var close_icon = $('.close-icon');
          var search_icon = $('.search-icon');
          var menu_nav = $('.mobile-menu');
          var menu_close_icon = $('.search-icon-close');
          var menu_open_icon = $('.search-icon-open');
          nav.hide();
          menu_nav.hide();
          menu_close_icon.hide();
          menu_open_icon.show();
          search.show();
          search_icon.hide();
          close_icon.show();
          $('.header-right').addClass('active');
        });

        $('.search-trigger .close-icon').on('click', function() {
          var nav = $('.nav-primary');
          var search = $('.search-header');
          var close_icon = $('.close-icon');
          var search_icon = $('.search-icon');
          var menu_nav = $('.mobile-menu');
          var menu_close_icon = $('.search-icon-close');
          var menu_open_icon = $('.search-icon-open');
          menu_nav.hide();
          menu_close_icon.hide();
          menu_open_icon.show();
          nav.show();
          search.hide();
          search_icon.show();
          close_icon.hide();
          $('.header-right').removeClass('active');
        });

        $('.share-trigger').click(function(e) {
          e.preventDefault();
          var share_list = $('.share-list');

          if (share_list.hasClass('active')) {
              share_list.removeClass('active');
          } else {
              share_list.addClass('active');
          }
        });

        $('.accordion-title').click(function() {
            var wrapper = $(this).parents('.accordion-wrapper');
            var content = wrapper.find('.accordion-section');

            if (content.hasClass('active')) {
                $(this).removeClass('active');
                wrapper.removeClass('active');
                content.removeClass('active');
            } else {
                $(this).addClass('active');
                wrapper.addClass('active');
                content.addClass('active');
            }
        });

        $('.show-popup-acc').on('click', function(e) {
            e.preventDefault();

            var id = $(this).data('id');
            var content = $('.page-content-hidden[data-id="' + id + '"]');

            content.addClass('active');
        });

        $('.close-popup').on('click', function() {
            $('.page-content-hidden').removeClass('active');
        });

        if ($('.blog .banner-images').length || $('.category .banner-images').length) {
            $('.banner-images').slick({
                infinite: true,
                dots: true,
                arrows: false,
                slidesToShow: 1,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: 'unslick'
                    }
                ]
            });
        }

        $('.sidebar-pages li.active a').on('click', function(e) {
            e.preventDefault();

            if ($(window).width() < 768) {
                var li =  $('.sidebar-pages ul li:not(.first)');

                if (li.hasClass('showLi')) {
                    li.removeClass('showLi');
                } else {
                    li.addClass('showLi');
                }
            }
        });

        $('.open-player').on('click', function(e) {
            e.preventDefault();

            var overflow = $('.player-popup-overflow');
            var popup = $('.player-popup');

            var image = $(this).data('image');
            var achternaam = $(this).data('achternaam');
            var voornaam = $(this).data('voornaam');
            var rugnummer = $(this).data('rugnummer');
            var nationaliteit = $(this).data('nationaliteit');
            var positie = $(this).data('positie');
            var geboortedatum = $(this).data('geboortedatum');
            var debuut = $(this).data('debuut');
            var vorige = $(this).data('vorige');

            if (popup.hasClass('active')) {
                popup.removeClass('active');
                overflow.removeClass('active');
            } else {
                popup.addClass('active');
                overflow.addClass('active');

                popup.find('.image').attr('src', image);
                popup.find('.surname').html(achternaam);
                popup.find('.name').html(voornaam);
                popup.find('.rugnummer').html(rugnummer);
                popup.find('.nationaliteit').html(nationaliteit);
                popup.find('.positie').html(positie);
                popup.find('.geboortedatum').html(geboortedatum);
                popup.find('.debuut').html(debuut);
                popup.find('.vorige').html(vorige);
            }

        });

        $('.close-popup').on('click', function() {
            var overflow = $('.player-popup-overflow');
            var popup = $('.player-popup');

            if (popup.hasClass('active')) {
                popup.removeClass('active');
                overflow.removeClass('active');
            }
        });

      },
      finalize: function() {
        $('.preserve-height').responsiveEqualHeightGrid();
      }
    },
    // Home page
    'home': {
      init: function() {

        if ($('.wedstrijden-box1-slider').length) {
          $('.wedstrijden-box1-slider').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            appendArrows: $('.wedstrijden-box1-slider-arrows'),
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>'
          });
        }

        if ($('.highlights-slider').length) {
          $('.highlights-slider').slick({
            slidesToScroll: 1,
            slidesToShow: 3,
            centerMode: false,
            variableWidth: true,
            adaptiveHeight: false,
            appendArrows: $('.highlights-arrows'),
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>'
          });
        }

        $('.home-top-link').click(function(e) {
          e.preventDefault();
          var href = $(this).data('href');

          $('.home-top-link').parents('li').removeClass('active');
          $(this).parents('li').addClass('active');
          $('.home-top-options').removeClass('active');
          $('#' + href).addClass('active');
          $('.preserve-height').responsiveEqualHeightGrid();
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
